
import {defineComponent} from "vue";
import InfoBox from "../../components/base/InfoBox.vue";
import RouterNavigate from "../../components/base/common/RouterNavigate.vue";
import OptOut from "@/components/base/OptOut.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import {useQuickActionStore} from "@/store/quick.action.store";

export default defineComponent({
  name: "MetricLayout",
  components: {
    HelpEmbed,
    OptOut,
    RouterNavigate, InfoBox
  },
  setup() {
    const quickActionStore = useQuickActionStore();

    return {
      quickActionStore,
    }
  },
  methods: {
    addMetric() {
      this.quickActionStore.setAction('METRIC');
    }
  }
})
